body {
  margin: 10px;
}

.icon {
    vertical-align: middle;
    margin-right: 5px;
    font-size: 20px;
  }

  .enabled {
    color: green;

  }

  .disabled {
    color: red;

  }

  .agConsulta {
    border-radius: 5px;
    background-color: #ffffff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    height: auto;
    width: 97%;
    padding: 15px;
    margin: 10px;
    align-content: space-around;
  }

  .Consulta {
    border-radius: 5px;
    background-color: #ffffff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    height: auto;
    width: 97%;
    /* padding: 15px; */
    margin: 10px;
    align-content: space-around;
    padding: 10px;

  }



.btConsultar {
    background-color: rgb(2, 173, 93);
    color: white;
    padding: 8px;
    border-radius: 5px;
    margin: 1px;
    border: none;
    cursor: pointer;
    width: 150px;
    height: 40px;
    font-size: 20px;
    margin-top: 1px;
    

}

.btLimpar {
    background-color: rgb(219, 5, 5);
    color: white;
    padding: 9px;
    border-radius: 5px;
    margin: 10px;
    border: none;
    cursor: pointer;
    width: 150px;
    font-size: 20px;
}

.iconeLoad {
    vertical-align: middle;
    padding: 0;
}

input {
    padding: 10px;
    border-radius: 5px;
    
    border: 0 2px 4px black;
}

h1 {
  margin: 10px;
}
p {
  margin: 10px;
}

table {
  border-collapse: collapse;
}

table th, table td {
  border: 1px solid black;
  padding: 8px;
}
